<template>
  <div class="list-academic-courses">
    <b-row class="academic-courses-header">
      <feather-icon
        :icon="isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="18"
        class="mr-1 cursor-pointer"
        @click="toggleCollapse"
      />
      <p>{{ $t("g.classrooms/allCourses/packages") }}</p>
      <hr class="border-2" />
    </b-row>
    <b-collapse id="packages" visible>
      <b-row v-if="packages.length > 0" class="academic-courses-body m-0">
        <b-card
          class="course-card cursor-pointer"
          v-for="item in packages"
          :key="item.id"
          :title="item.name"
          :img-src="
            item.image[0] ? item.image[0].path : $helpers.uiAvatar(item.name)
          "
          :img-alt="item.name"
          img-top
        >
          <b-card-text>
            {{ `${item.sale ? item.sale : item.price} ${currency}` }}
            <s v-if="item.sale">
              {{ `${item.price} ${currency}` }}
            </s>
          </b-card-text>
          <b-row>
            <b-button
              :disabled="is_loading"
              id="button"
              class="mx-1"
              @click="addToCart(item.id)"
            >
              {{ $t("g.explore_add_to_cart") }}
            </b-button>
            <b-button
              :disabled="is_loading"
              class="button-link"
              @click="
                $router.push({
                  name: 'ShowPackage',
                  params: { id: item.id },
                })
              "
            >
              {{ $t("g.explore_course_view") }}
            </b-button>
          </b-row>
        </b-card>
      </b-row>
      <b-row v-else align-h="center">
        <h2 style="color: #135991">
          {{ $t("there_is_no_packages") }}
        </h2>
      </b-row>
    </b-collapse>
    <overlay-component :isLoading="is_loading" />
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BCollapse, BRow } from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
export default {
  name: "Packages",
  components: {
    BRow,
    BCard,
    BButton,
    BCollapse,
    BCardText,
    OverlayComponent,
  },
  props: {
    packages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCollapsed: false,
      is_loading: false,
    };
  },
  computed:{
    currency(){
      return this.$store.state?.userData?.userData?.country?.currency
    }
  },
  methods: {
    toggleCollapse() {
      this.$root.$emit("bv::toggle::collapse", "packages");
      this.isCollapsed = !this.isCollapsed;
    },
    async addToCart(packageId) {
      try {
        this.is_loading = true;
        const formData = new FormData();
        formData.append("package_id", packageId);
        formData.append("qty", 1);
        await this.$http.post("/guardian/cart", formData);
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("g.course_added_to_cart_successfully")
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
