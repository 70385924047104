<template>
  <div class="academic-courses">
    <loading v-if="is_loading" />
    <div v-else>
      <b-row class="academic-courses-header">
        <p class="academic-courses-title">
          {{ edu_year.name }}
        </p>
        <hr class="border-2" />
      </b-row>
      <packages :packages="packages" />
      <b-row class="mt-2 academic-courses-header">
        <feather-icon
          :icon="isCollapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="18"
          class="mr-1 cursor-pointer"
          @click="toggleCollapse"
        />
        <p>{{ $t("g.classrooms/allCourses/courses") }}</p>
        <hr class="border-2" />
      </b-row>
      <b-collapse id="courses" visible>
        <explore-course-card-component
          :coursesData="courses"
          :canAddToCart="true"
          @createCourse="createCourse"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BRow, BCollapse } from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import Packages from "@/views/classrooms/academicCourses/packages/index.vue";
import ExploreCourseCardComponent from "@/components/Explore/CourseCard/ExploreCourseCardComponent.vue";

export default {
  name: "AcademicCourses",
  components: {
    Packages,
    BRow,
    BCollapse,
    loading,
    ExploreCourseCardComponent,
  },
  data() {
    return {
      isCollapsed: false,
      courses: [],
      packages: [],
      edu_year: {},
      is_loading: true,
    };
  },
  beforeMount() {
    this.getEduYearCourses();
    this.getEduYear();
    this.getPackages();
  },
  methods: {
    async getEduYear() {
      try {
        const res = await this.$http.get(
          `/guardian/edu-year/${this.$route.params.id}`
        );
        this.edu_year = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      }
    },
    async getEduYearCourses() {
      try {
        const res = await this.$http.get(
          `/guardian/courses?edu_year_id=${this.$route.params.id}`
        );

        this.courses = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    async getPackages() {
      try {
        const request = await this.$http.get(
          `guardian/packages?edu_year_id=${this.$route.params.id}`
        );
        if (request.status === 200 || request.status === 201) {
          this.packages = request.data.data;
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.is_loading = false;
      }
    },
    createCourse() {
      this.getEduYearCourses();
    },
    toggleCollapse() {
      this.$root.$emit("bv::toggle::collapse", "courses");
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
